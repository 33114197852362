import React from "react"
import { css } from "@emotion/core"
import { Container } from "reactstrap"

import Header from "../components/header"
import Footer from "../components/footer"

const notFound = css({
    textAlign: "center",
    fontFamily: "'Halant', serif",
    padding: "30vh 0",
    "& h1": {
        fontSize: 56,
    },
    "& p": {
        fontSize: 18,
    },
})

export default () => (
    <div>
        <Header/>
        <Container css={notFound}>
            <h1>404</h1>
            <p>Not found</p>
        </Container>
        <Footer/>
    </div>
)
